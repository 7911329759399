/* @import url('https://fonts.googleapis.com/css?family=Josefin+Sans'); */


#forShowcase {
    background:url('./rightArrow.png');
    background-size: 23px;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
}

@media only screen and (max-width: 768px) {

    #forShowcase {
        background:transparent;
    }
}

/* ul {
    list-style-image: url('./checkmark.png');
  } */

  .customli {
    display: flex;
    align-items: center;
    margin: 10px 0;

    line-height: 30px;

    list-style: none;
}

.customli:before {
    display: block;
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    margin-right: 10px;

    vertical-align: middle;

    background: url('./checkmark.png') no-repeat left center;
    background-size: contain;

    content: '';
}
  
  /* ul li:before {
    content: '✓';
  } */