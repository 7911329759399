body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* .customHr {
  position: relative;
  z-index: 1;

}

.customHr:before {
  border-top: 2px solid #dfdfdf;
  content:"";
  margin: 0 auto; 
  position: absolute; 
  top: 50%; left: 0; right: 0; bottom: 0;
  width: 95%;
  z-index: -1;
} */


.customHr {
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
}
.customHr:before {
  position: absolute;
  top: 25%;
  overflow: hidden;
  width: 25%;
  height: 1px;
  content: '\a0';
  background-color: grey;
  margin-left: -50%;
  text-align: right;

}

.customHr:after {
  position: absolute;
  top: 25%;
  overflow: hidden;
  width: 25%;
  height: 1px;
  content: '\a0';
  background-color: grey;
  margin-left: 25%;
  text-align: right;

}

.outerDiv{
  margin-top: 5%;
  width:100%; 
  height:870px; 
  overflow:hidden; 
  position:relative; 
}


.customRow:hover{
  background:#0c1218;
  cursor: pointer;
}

.customCard{
  background:#1e2023;
}
.customCard:hover{
  background:#333538;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

